import React from 'react';
import { AppBarRoute } from 'components/skipper/appBar/models';
import _get from 'lodash/get';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';

// Components
import AvatarOld from 'components/skipper/AvatarOld';
import Drawer from '@mui/material/Drawer';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import MenuItems from 'components/skipper/appBar/MenuItems';
import AppLink from 'components/skipper/appBar/AppLink';
import SkipTranslate from 'components/atoms/SkipTranslate';

// Styles
import { makeStyles } from 'tss-react/mui';
import brandColors from 'styles/skipper/brandColors';

const useStyles = makeStyles()((theme: any) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 130,
      minHeight: 130,
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    headerLink: {
      marginRight: theme.spacing(1),
      textDecoration: 'none',
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    headerAvatar: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      border: `1px solid ${theme.palette.getContrastText(theme.palette.primary.main)}`,
      width: 46,
      height: 46,
    },
    headerArrowContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      cursor: 'pointer',
    },
    headerArrow: {
      padding: 10,
      height: 50,
      width: 50,
      cursor: 'pointer',
    },
    drawerPaper: {
      minWidth: 260,
    },
    copyEllipsis: {
      width: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});

interface Props {
  className?: string;
  onClose?: () => void;
  open: boolean;
  adminRoute?: AppBarRoute;
  navBarRoutes?: AppBarRoute[];
  accountRoutes?: AppBarRoute[];
  additionalRoutes?: AppBarRoute[];
  person: {
    id: string;
    displayName: string;
    title: string;
    accountOnly: boolean;
    products: { [key: string]: boolean | undefined };
  };
  pictureUrl?: string;
  theme?: any;
}
function AppDrawer({
  className,
  pictureUrl,
  adminRoute,
  navBarRoutes,
  accountRoutes,
  additionalRoutes,
  person,
  open,
  onClose,
  theme,
}: Props) {
  const { classes } = useStyles();
  const primaryDark = _get(theme, ['palette', 'primary', 'dark'], brandColors.common.black);
  const defaultPictureUrl = createPictureUrlFromColor(primaryDark);

  const avatarContent = (
    <>
      <AvatarOld
        className={classes.headerAvatar}
        pictureUrl={pictureUrl || defaultPictureUrl}
        alt={person.displayName}
      />

      <SkipTranslate className={classes.copyEllipsis}>{person.displayName}</SkipTranslate>
      <div className={classes.copyEllipsis}>{person.title}</div>
    </>
  );

  return (
    <Drawer
      className={className}
      open={!!open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.header} onClick={onClose}>
        <div className={classes.headerArrowContainer}>
          <KeyboardArrowLeft className={classes.headerArrow} />
        </div>

        {person.products.directory && !person.accountOnly ? (
          <AppLink className={classes.headerLink} to={`/profile/${person.id}`}>
            {avatarContent}
          </AppLink>
        ) : (
          <div className={classes.headerLink}>{avatarContent}</div>
        )}
      </div>
      <MenuItems
        adminRoute={adminRoute}
        navBarRoutes={navBarRoutes}
        accountRoutes={accountRoutes}
        additionalRoutes={additionalRoutes}
        person={person}
        onClose={onClose}
      />
    </Drawer>
  );
}

export default AppDrawer;

// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAnalytics from 'helpers/useAnalytics';
import { useHistory } from 'react-router';
import typography from 'helpers/typography';

// UI
import SearchInput from './SearchInput';

import { makeStyles } from 'tss-react/mui';
import bigSearch from './styles';
import { transparentize } from 'polished';
import { SEARCH_PATH } from 'components/search/consts';

const useStyles = makeStyles()(theme => {
  const bigSearchStyles = bigSearch(theme);

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.getContrastText(theme.palette.primary.main),
      marginTop: theme.spacing(1),
      flex: '0 0 auto',
      width: '95%',
      maxWidth: 1420,
    },
    titleRow: {
      ...typography(theme, 'h6'),
      color: transparentize(0.4, theme.palette.getContrastText(theme.palette.primary.main)),
      flex: '1 0 auto',
      marginBottom: theme.spacing(1),
    },
    inputRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    subtitleRow: {
      flex: '1 0 auto',
      marginBottom: 5,
      minHeight: 72,
      ...typography(theme, 'body2'),
      fontWeight: theme.typography.fontWeightNormal,
    },
    inputContainer: {
      paddingRight: 0,
    },
    suggestionChip: {
      ...bigSearchStyles.components.card,
      userSelect: 'none',
      cursor: 'pointer',
      borderRadius: 5,
      display: 'inline-flex',
      height: 25,
      alignItems: 'center',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      textTransform: 'capitalize',
    },
  };
});

function SearchRow({
  suggestions,
  onShowAdvancedSearch,
  query,
  updateRecentSearches,
  fetchAutocomplete,
  updateQuery,
  clearQuery,
}) {
  const analytics = useAnalytics();
  const history = useHistory();
  const { classes } = useStyles();

  useEffect(() => {
    const q = query.trim();

    if (!q) {
      return;
    }

    fetchAutocomplete({
      q,
      useAggregateCounts: true,
      filterAggregate: true,
    });
  }, [fetchAutocomplete, query]);

  const handleEnter = () => {
    if (query) {
      updateRecentSearches({
        query: {
          q: query,
        },
        type: 'search',
      });
    }

    history.push(`${SEARCH_PATH}?q=${query}`);
  };

  const handleInputChange = event => {
    updateQuery(event.target.value);
  };

  const handleInputKeyDown = event => {
    if (event.key === 'Enter') {
      analytics.track('Searched by Enter', {
        category: 'Big Search',
        label: event.target.value,
      });

      handleEnter();
    }
  };

  const handleSuggestionClick = suggestion => {
    analytics.track('Recommended Term Clicked', {
      category: 'Big Search',
      label: suggestion,
    });

    updateQuery(suggestion);
  };

  let searchSuggestions = 'Choose from the list below or press "Enter" to see all results.';
  if (suggestions.length) {
    let suggestionsElems = suggestions.map((suggestion, index) => {
      return (
        <div
          className={classes.suggestionChip}
          onClick={() => handleSuggestionClick(suggestion)}
          key={suggestion}
        >
          {suggestion}
        </div>
      );
    });

    searchSuggestions = (
      <div>
        <span
          style={{
            marginRight: '5px',
          }}
        >
          Recommendations:{' '}
        </span>
        {suggestionsElems}
      </div>
    );
  }

  // column layout
  return (
    <div className={classes.root}>
      <SearchInput
        value={query}
        onChange={handleInputChange}
        handleClear={clearQuery}
        onKeyDown={handleInputKeyDown}
        onSearch={handleEnter}
        handleShowAdvancedSearchClick={onShowAdvancedSearch}
        data-cy="search-row--input"
      />
      <div className={classes.subtitleRow}>{searchSuggestions}</div>
    </div>
  );
}

SearchRow.propTypes = {
  query: PropTypes.string,
  suggestions: PropTypes.array,
  updateRecentSearches: PropTypes.func,
  clearQuery: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  fetchAutocomplete: PropTypes.func.isRequired,
  onShowAdvancedSearch: PropTypes.func,
};

export default SearchRow;

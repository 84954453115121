import React from 'react';
import _get from 'lodash/get';
import { useData } from 'resift';
import { oneLine } from 'common-tags';
import { matchPath } from 'react-router';
import hasBadContrast from 'styles/skipper/hasBadContrast';
import typography from 'helpers/typography';
import useIsAdmin from 'helpers/useIsAdmin';
import useFullscreen from 'helpers/useFullscreen';
import getIsInMicrosoft from 'helpers/getIsInMicrosoft';
import useNavDropdownRoutes from 'components/Navigation/helpers/useNavDropdownRoutes';
import getClientOrgChart from 'fetches/getClientOrgChart';
import { HAMBURGER_BREAK_POINT, HIDE_SEARCH_BREAK_POINT } from './consts';

// Styles
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import classNames from 'classnames';
import readableColorForWhite from 'styles/skipper/readableColorForWhite';
import isWhitish from 'styles/skipper/isWhitish';

// Components
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DefaultNavigation from 'components/Navigation/DefaultNavigation';
import MsTeamsNavigation from 'components/msTeams/MsTeamsNavigation';

import * as Sift from '@sift/types';

interface Props {
  person: Sift.Person;
  client: any;
  pathname: string;
  pictureUrl?: string;
  logoUrl?: string;
  query?: string;
  location: any;
  toggleBigSearch: () => void;
  toggleSupportDrawer: () => void;
}

function Navigation({
  person,
  client,
  pathname,
  pictureUrl,
  logoUrl,
  query,
  location,
  toggleBigSearch,
  toggleSupportDrawer,
}: Props) {
  const { classes } = useStyles();
  const fullscreen = useFullscreen();
  const isInMicrosoft = getIsInMicrosoft();
  const isAdmin = useIsAdmin();
  const isOnHomePage = pathname === '/';
  const clientOrgChart = useData(getClientOrgChart);

  const isOnboarding = !!matchPath(pathname, {
    path: '/onboarding',
  });
  const isOnSettingsPageOnMobile =
    !!matchPath(pathname, {
      path: '/settings',
    }) && location.search.includes('mobile=true');

  const shouldHideOrgChartNav =
    person.accountOnly && !_get(clientOrgChart, 'rootNodes.length') && !isAdmin;
  const orgChartEnabled = !shouldHideOrgChartNav;

  const dropdownRoutes = useNavDropdownRoutes({
    personId: person.id,
    orgChartEnabled,
    hasProfile: !person.accountOnly,
    onToggleSupportDrawer: toggleSupportDrawer,
  });

  const searchBarClass = classNames({ [classes.hide]: isOnHomePage }, classes.searchBar);

  if (fullscreen.isFullscreen || isOnboarding || isOnSettingsPageOnMobile) {
    return null;
  }

  return (
    <div
      className={classNames(classes.root, 'unselectable', {
        // Hide the search bar on homepage
        [classes.bigSearchOverrides]: isOnHomePage,
        [(classes.rootBoxShadow, 'unselectable', 'sift-navigation', 'print-hidden')]: !isOnHomePage,
      })}
    >
      {isInMicrosoft ? (
        <MsTeamsNavigation
          person={person}
          routes={dropdownRoutes.msTeamsRoutes}
          searchBarClass={searchBarClass}
          query={query}
          onClickSearch={toggleBigSearch}
        />
      ) : (
        <DefaultNavigation
          person={person}
          pictureUrl={pictureUrl}
          logoUrl={logoUrl}
          query={query}
          dropdownRoutes={dropdownRoutes}
          searchIcon={
            isOnHomePage ? null : (
              <Link to={'/'}>
                <SearchIcon className={classNames(classes.bigSearchIcon, classes.navIcons)} />
              </Link>
            )
          }
          searchBarClass={searchBarClass}
          responsiveClasses={{
            tabletContainer: classes.tabletContainer,
            routeContainer: classes.routeContainer,
          }}
          orgChartEnabled={orgChartEnabled}
          onClickSearch={toggleBigSearch}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles()((theme: Theme) => {
  const { primary, common } = theme.palette;
  const searchTextColor = hasBadContrast(primary.main, common.white)
    ? theme.palette.getContrastText(theme.palette.primary.main)
    : readableColorForWhite(primary.main);

  return {
    root: {
      flex: '0 0 auto',
      display: 'flex',
      alignItems: 'center',
      background: primary.main,
      padding: theme.spacing(0, 3),
      minHeight: 65,
      [HAMBURGER_BREAK_POINT]: {
        padding: theme.spacing(1.25, 3, 1.25, 0),
      },
    },
    rootBoxShadow: {
      zIndex: theme.zIndex.modal,
      boxShadow: theme.shadows[4],
    },

    // Search Bar
    searchBar: {
      ...typography(theme, 'body1'),
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      opacity: 1,
      transition: 'opacity 200ms',
      position: 'relative',
      cursor: 'pointer',
      boxShadow: isWhitish(primary.main)
        ? 'none'
        : oneLine`
          0px 1px 3px 0px rgba(0,0,0,0.2),
          0px 1px 1px 0px rgba(0,0,0,0.14),
          0px 2px 1px -1px rgba(0,0,0,0.12)
        `,
      backgroundColor: isWhitish(primary.main) ? 'rgba(0, 0, 0, 0.09)' : common.white,
      padding: theme.spacing(1, 1, 1, 5),
      borderRadius: 2,
      color: searchTextColor,
      '&:hover': {
        backgroundColor: isWhitish(primary.main) ? 'rgba(0, 0, 0, 0.13)' : undefined,
      },
      [HIDE_SEARCH_BREAK_POINT]: {
        display: 'none',
      },
    },

    // Routes
    routeContainer: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [HAMBURGER_BREAK_POINT]: {
        display: 'none',
      },
    },

    // Misc
    hide: {
      opacity: 0,
      display: 'none',
    },
    bigSearchOverrides: {
      background: primary.main,
      boxShadow: 'none',
    },

    // Hamburger Menu
    navIcons: {
      flex: '0 0 auto',
      color: theme.palette.getContrastText(primary.main),
      width: 36,
      height: 36,
      cursor: 'pointer',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    bigSearchIcon: {
      display: 'none',
      [HIDE_SEARCH_BREAK_POINT]: {
        display: 'block',
      },
    },
    tabletContainer: {
      display: 'none',
      [HAMBURGER_BREAK_POINT]: {
        display: 'flex',
      },
    },
  };
});

export default Navigation;

// Libs
import React from 'react';
import PropTypes from 'prop-types';

// UI
import Grid from '@mui/material/Grid';
import DateRangePicker from './DateRangePicker';
import Autosuggest from './Autosuggest';
import TextField from './TextField';

import { makeStyles } from 'tss-react/mui';

function AdvancedSearchForm({
  value,
  autocompleteResults = {},
  config,
  onChange,
  fetchAutocomplete,
}) {
  const { classes } = useStyles();

  const onFieldChange = (key, fieldValue) => {
    if (fieldValue === null || fieldValue === '') {
      let nextFormValue = { ...value };
      delete nextFormValue[key];
      onChange(nextFormValue);
      return;
    }

    onChange({
      ...value,
      [key]: fieldValue,
    });
  };

  const onSuggestionSelected = (fieldKey, fieldValue) => {
    onFieldChange(fieldKey, fieldValue);
  };

  const onSuggestionsFetchRequested = (fieldKey, fieldValue) => {
    fetchAutocomplete(fieldKey, fieldValue);
  };

  const layout = config.user.layouts['advanced-search'];

  if (!layout) {
    return null;
  }

  return (
    <Grid container spacing={2} direction="column" wrap="wrap" className={classes.root}>
      {layout.map((layoutItem, index) => {
        // Get the Field
        const field = config.user.fields[layoutItem.name];
        let fieldView;

        // Text
        if (field.input.type !== 'date') {
          fieldView = (
            <TextField
              value={value[layoutItem.name]}
              label={field.displayName}
              onChange={onFieldChange}
              fieldKey={layoutItem.name}
              className={classes.textField}
              key={layoutItem.name}
            />
          );

          if (layoutItem.autoComplete) {
            // AUTOCOMPLETE
            fieldView = (
              <Autosuggest
                inputProps={{
                  value: value[layoutItem.name] || '',
                  onChange: onFieldChange,
                  textFieldProps: {
                    label: field.displayName,
                    className: classes.textField,
                  },
                }}
                suggestions={autocompleteResults[layoutItem.name]}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                fieldKey={layoutItem.name}
                key={layoutItem.name}
              />
            );
          }
        }
        // Date
        else {
          fieldView = (
            <DateRangePicker
              label={field.displayName}
              onChange={onFieldChange}
              value={value[layoutItem.name]}
              fieldKey={layoutItem.name}
              className={classes.textField}
            />
          );
        }

        return (
          <Grid
            item
            md={4}
            xs={12}
            className={classes.gridItem}
            key={`${layoutItem.name}-${index}`}
          >
            {fieldView}
          </Grid>
        );
      })}
    </Grid>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      msFlexFlow: 'row wrap',
      [theme.breakpoints.up('md')]: {
        maxHeight: 400,
      },
      '& label': {
        color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
      },
    },
    gridItem: {
      marginBottom: theme.spacing(1),

      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    textField: {
      width: '90%',

      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  };
});

AdvancedSearchForm.propTypes = {
  value: PropTypes.object,
  autocompleteResults: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  fetchAutocomplete: PropTypes.func,
  config: PropTypes.object,
};

export default AdvancedSearchForm;

import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import MUITextField from '@mui/material/TextField';

import { makeStyles } from 'tss-react/mui';

function TextField({ value, fieldKey, onChange, ...other }) {
  const { classes } = useStyles();

  const handleChange = event => {
    if (event && event.target) {
      onChange(fieldKey, _get(event, 'target.value', ''));
    }
  };

  return (
    <MUITextField
      value={value || ''}
      className={classes.root}
      {...other}
      InputProps={{
        classes: {
          root: classes.color,
          underline: classes.underline,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.color,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.color,
        },
      }}
      onChange={handleChange}
      variant="standard"
    />
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& input::-ms-clear': {
        display: ' none !important',
      },
      marginBottom: theme.spacing(),
    },
    color: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    underline: {
      '&:after': {
        borderColor: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
      },
      '&:before': {
        borderColor: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
      },
    },
  };
});

TextField.propTypes = {
  fieldKey: PropTypes.string,
  focus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextField;

// Libs
import React, { useState } from 'react';

// UI
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import { PickersDay, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';

// Styles
import { useTheme, styled } from '@mui/material/styles';
import hasBasContrast from 'styles/skipper/hasBadContrast';

const CustomTextField = styled(TextField)(({ theme }) => {
  const contrastText = theme.palette.getContrastText(theme.palette.primary.main);

  return {
    '& .MuiInput-root': {
      marginRight: theme.spacing(),
    },
    '& label.Mui-focused': {
      color: contrastText,
    },
    '& .MuiInput-input': {
      color: contrastText,
    },
    '& .MuiIconButton-root': {
      color: contrastText,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: contrastText,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: contrastText,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: contrastText,
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: contrastText,
    },
  };
});

function DatePicker({ ...props }) {
  const theme = useTheme();
  const [helperText, setHelperText] = useState(null);
  const hasBadContrastWithPaper = color => hasBasContrast(theme.palette.background.paper, color);
  const { primary, secondary, defaultAccentColor } = theme.palette;
  const contrastColorForPaper = hasBadContrastWithPaper(primary.main)
    ? secondary.main
    : primary.main;

  const backgroundColor =
    hasBadContrastWithPaper(primary.main) && hasBadContrastWithPaper(secondary.main)
      ? defaultAccentColor.main
      : contrastColorForPaper;

  const renderDayPicker = (_date, _selectedDates, pickersDayProps) => {
    return (
      <PickersDay
        {...pickersDayProps}
        sx={{
          [`&&.${pickersDayClasses.today}`]: {
            borderColor: backgroundColor,
          },
        }}
      />
    );
  };

  return (
    <MuiDatePicker
      inputFormat="M/D/YYYY"
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      views={['day', 'month', 'year']}
      onError={(reason, value) =>
        reason && value ? setHelperText('Please enter a valid date') : setHelperText(null)
      }
      renderDay={renderDayPicker}
      renderInput={params => (
        <CustomTextField variant="standard" helperText={helperText} {...params} />
      )}
      {...props}
    />
  );
}

export default DatePicker;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import typography from 'helpers/typography';
import LayoutContext from 'components/BigSearch/Results/LayoutContext';

import { makeStyles } from 'tss-react/mui';

// Components
import Chip from 'components/skipper/Chip';

/**
 * A standard Big Search results column.
 *
 *   ______________________
 *  | Title         Action |
 *  ------------------------
 *  |                      |
 *  |       Items          |
 *  |                      |
 *  ------------------------
 *
 * Columns wrapping this should follow
 * an API similar to the `ResultColumn`
 * which uses `result`, `query`.
 */
function Column(props) {
  const { title, action, children, itemsStyle, isTopMatch } = props;
  const layout = useContext(LayoutContext);
  const { classes } = useStyles({ title, layout });
  const isSkillOrInterest = title === 'Skills' || title === 'Interests';

  const titleContent = () => {
    if (isSkillOrInterest) {
      const color = title.toLowerCase();

      return <Chip className={classes.chip} label={title} color={color} />;
    }

    return title;
  };

  return (
    <div className={classNames(classes.root, { [classes.topMatch]: isTopMatch })}>
      <div
        className={classNames(classes.header, {
          [classes.chipMargin]: isSkillOrInterest,
        })}
      >
        <div>{titleContent()}</div>
        {action}
      </div>
      <div className={classes.items} style={isTopMatch ? {} : itemsStyle}>
        {children}
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme, { title, layout }) => {
  let itemsHeight = layout.maxColumnHeight();
  const primaryMainColor = theme.palette.primary.main.toLowerCase();
  const skillsMainColor = theme.palette.skills.main.toLowerCase();
  const interestsMainColor = theme.palette.interests.main.toLowerCase();
  const shouldApplySkillsBorder = title === 'Skills' && primaryMainColor === skillsMainColor;
  const shouldApplyInterestsBorder =
    title === 'Interests' && primaryMainColor === interestsMainColor;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    topMatch: {
      marginLeft: 20,
    },
    header: {
      ...typography(theme, 'body2'),
      fontWeight: theme.typography.fontWeightBold,
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
      color: theme.palette.getContrastText(theme.palette.primary.main),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      textTransform: 'uppercase',
      '& > :first-of-type': {
        flex: '1 1 auto',
      },
    },
    chip: {
      border: shouldApplySkillsBorder
        ? `1px solid ${theme.palette.skills.contrastText}`
        : shouldApplyInterestsBorder
        ? `1px solid ${theme.palette.interests.contrastText}`
        : 'inherit',
    },
    chipMargin: {
      marginBottom: theme.spacing(1.25),
    },
    items: {
      display: 'flex',
      flexFlow: 'column wrap',
      flex: '1 1 auto',
      maxHeight: itemsHeight,
      height: '100%',
      overflowY: 'visible',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'none',
        height: 'inherit',
      },
    },
  };
});

Column.propTypes = {
  title: PropTypes.string,
  action: PropTypes.node,
  children: PropTypes.node,
  itemsStyle: PropTypes.object,
  isTopMatch: PropTypes.bool,
};

export default Column;

import React, { useState } from 'react';
import getPictureUrl from 'helpers/getPictureUrl';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import classNames from 'classnames';
import { HAMBURGER_BREAK_POINT } from '../consts';

import ReorderIcon from '@mui/icons-material/Reorder';
import AppDrawer from 'components/skipper/appBar/Drawer';

import * as Types from 'components/Navigation/typings';

interface Props {
  person: any;
  pictureUrl?: string;
  adminRoute?: Types.Route;
  navBarRoutes?: Types.Route[];
  accountRoutes?: Types.Route[];
  additionalRoutes?: Types.Route[];
}

function NavHamburgerMenu({
  person,
  pictureUrl,
  adminRoute,
  navBarRoutes,
  accountRoutes,
  additionalRoutes,
}: Props) {
  const { classes, theme } = useStyles();
  const [open, setOpen] = useState(false);
  const preferOfficialPhotos = useSelector(state =>
    _get(state, 'settings.directory.preferOfficialPhotos', false),
  );
  const officialPictureUrl = person.officialPictureUrl;
  const _pictureUrl = getPictureUrl(
    pictureUrl || person.pictureUrl,
    officialPictureUrl,
    preferOfficialPhotos,
    theme,
  );

  const handleToggleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <ReorderIcon
        className={classNames(classes.navIcons, classes.hamburgerIcon)}
        onClick={handleToggleDrawerOpen}
      />
      <AppDrawer
        person={person}
        pictureUrl={_pictureUrl}
        open={open}
        onClose={handleToggleDrawerOpen}
        adminRoute={adminRoute}
        navBarRoutes={navBarRoutes}
        accountRoutes={accountRoutes}
        additionalRoutes={additionalRoutes}
      />
    </>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  navIcons: {
    flex: '0 0 auto',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    width: 36,
    height: 36,
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  hamburgerIcon: {
    display: 'none',
    [HAMBURGER_BREAK_POINT]: {
      display: 'block',
    },
  },
}));

export default NavHamburgerMenu;

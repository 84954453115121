import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import delay from 'delay';
import usePortalPlaceholder from 'helpers/skipper/usePortalPlaceholder';
import readableColor from 'styles/skipper/readableColor';

// Component
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';
import Navigation from 'components/Navigation';
import CoreSnackbar from 'components/CoreSnackbar';
import SupportTab from 'components/Support';
import MobileAppGetter from 'components/MobileAppGetter/MobileAppGetter';
import ThemeSnackbar from 'components/ThemeSnackbar';
import BannerPortal from 'components/BannerPortal';
import UpdateBanner from 'components/UpdateBanner';
import IeSucksBanner from 'pages/OrgChart/components/IeSucksBanner';
import ReadableThemedComponent from 'styles/skipper/ReadableThemedComponent';
import FreeTrialBanner from 'components/FreeTrialBanner';

// Styles
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

import { MS_TEAMS_START_URL, MS_TEAMS_LOGIN_URL } from 'components/msTeams/consts';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    childrenRoot: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      overflowY: 'auto',
      flex: '1 1 auto',
      background: theme.palette.background.paper,
      position: 'relative',
    },
    ieBanner: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(0.5),
    },
    link: {
      color: readableColor(theme.palette.secondary.main),
      textDecorationColor: readableColor(theme.palette.secondary.main),
    },
    bannerContentMargin: {
      marginRight: theme.spacing(0.5),
    },
  };
});

function CoreLayout({ children }) {
  const { classes } = useStyles();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const isMobileView = useState(!!queryParams['mobileView'])[0];
  const [bannerOpen, setBannerOpen] = useState(true);
  const client = useSelector(state => state.client);

  const trialEndDate = client.trialEndDate
    ? moment(client.trialEndDate)
    : moment(client.assignedAt).add(14, 'days');

  const currentDay = moment(new Date());

  const trialDaysRemaining = trialEndDate.diff(currentDay, 'days');

  const handleClose = () => {
    setBannerOpen(false);
  };

  useEffect(() => {
    delay(1000).then(() => {
      window.scrollTo(0, 1);
    });
  }, []);

  const BannerPlaceholder = usePortalPlaceholder(BannerPortal);

  return (
    <div className={classNames(classes.root, 'core-layout')}>
      <MobileAppGetter />
      <UpdateBanner />

      {client.isTrial && bannerOpen && trialDaysRemaining >= 0 && (
        <FreeTrialBanner onClose={handleClose} trialDaysRemaining={trialDaysRemaining} />
      )}
      <IeSucksBanner
        useSecondaryColor
        content={
          <div className={classes.ieBanner}>
            <InfoIcon className={classes.bannerContentMargin} />
            <span className={classes.bannerContentMargin}>IE11 users will experience</span>
            <Link
              href="https://learn.microsoft.com/en-us/lifecycle/announcements/internet-explorer-11-end-of-support"
              rel="noreferrer noopener"
              target="_blank"
              className={classNames(classes.bannerContentMargin, classes.link)}
              underline="always"
            >
              partial loss of functionality
            </Link>
            after September 29, 2022.
          </div>
        }
      />
      {!isMobileView &&
        // Pearl TODO: this can be deleted once our new app is approved by the store
        (location.pathname !== MS_TEAMS_START_URL || location.pathname !== MS_TEAMS_LOGIN_URL) && (
          <Navigation />
        )}
      <BannerPlaceholder />
      <div className={classNames(classes.childrenRoot, 'core-layout-children')}>
        <ReadableThemedComponent color="#fff">{children}</ReadableThemedComponent>
        <CoreSnackbar />
        <ThemeSnackbar />
        <SupportTab />
      </div>
    </div>
  );
}

CoreLayout.propTypes = {
  children: PropTypes.node,
};

export default CoreLayout;

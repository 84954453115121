import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import typography from 'helpers/typography';
import useAnalytics from 'helpers/useAnalytics';

// Components
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import AdvancedSearchForm from './AdvancedSearchForm';
import SearchFooter from './SearchFooter';

import { makeStyles } from 'tss-react/mui';

function AdvancedSearch({
  onClose,
  autocompleteResults,
  fetchFieldAutocomplete,
  onSearch,
  className,
  ...other
}) {
  const { classes } = useStyles();
  const analytics = useAnalytics();
  const [formValue, setFormValue] = useState({});

  const clearSearch = () => {
    setFormValue({});
  };

  const handleFormChange = value => {
    setFormValue(value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    analytics.track('Hit Enter to execute Advanced Search', {
      category: 'Big Search',
    });

    handleSearch();
  };

  const handleSearch = () => {
    onSearch(formValue);
  };

  return (
    <div className={classNames(classes.root, className)} {...other}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item className={classes.title}>
          <div> Advanced Search </div>
          <div>
            <Button
              variant="contained"
              onClick={onClose}
              color="secondary"
              startIcon={<ChevronLeftIcon />}
            >
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <AdvancedSearchForm
        value={formValue}
        onChange={handleFormChange}
        onSubmit={handleSubmit}
        autocompleteResults={autocompleteResults}
        fetchAutocomplete={fetchFieldAutocomplete}
      />
      <SearchFooter
        clearSearch={clearSearch}
        onSearch={handleSearch}
        disabled={!Object.keys(formValue).length}
      />
    </div>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      marginTop: 100,
      marginBottom: 50,
      [theme.breakpoints.down('lg')]: {
        marginTop: 0,
        padding: theme.spacing(2),
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      ...typography(theme, 'h2'),
      fontWeight: 500,
      color: theme.palette.getContrastText(theme.palette.primary.main),

      [theme.breakpoints.down('lg')]: {
        ...typography(theme, 'h5'),
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
    },
  };
});

AdvancedSearch.propTypes = {
  className: PropTypes.string,
  autocompleteResults: PropTypes.object,
  fetchFieldAutocomplete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default AdvancedSearch;

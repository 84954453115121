import { defineFetch } from 'resift';

const makeGetHomepageLayout = defineFetch({
  displayName: 'Get Homepage Layout',
  make: (layoutId: string) => ({
    request: () => ({ http }) =>
      http({
        method: 'GET',
        route: `/homepage-layouts/${layoutId}?expandWidgetData=true`,
      }),
  }),
});

export default makeGetHomepageLayout;

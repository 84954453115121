import usePassiveRedux from '@sift/resift/usePassiveRedux';
import _get from 'lodash/get';
import * as Sift from '@sift/types';
import * as EntityTyping from 'pages/Entity/typings';
import { useTheme } from '@mui/material/styles';
import createPictureUrlFromColor from 'helpers/createPictureUrlFromColor';
import createOpenRolePictureUrlFromColor from 'helpers/createOpenRolePictureUrlFromColor';
import { OPEN_ROLE_ENTITY_KEY } from 'pages/OrgChart/consts';

function usePictureUrl(entity: Sift.Person | EntityTyping.Entity) {
  const theme = useTheme() as any;

  const preferOfficialPhotos = usePassiveRedux(state =>
    _get(state, ['settings', 'directory', 'preferOfficialPhotos'], false),
  ) as boolean;

  if (entity?.entityTypeKey === OPEN_ROLE_ENTITY_KEY) {
    return entity.pictureUrl ?? createOpenRolePictureUrlFromColor(theme.palette.primary.dark);
  }

  const _pictureUrl = preferOfficialPhotos
    ? _get(entity, 'officialPictureUrl') || _get(entity, 'pictureUrl')
    : _get(entity, 'pictureUrl');

  if (!_pictureUrl) {
    return undefined;
  }

  const pictureUrl =
    _pictureUrl && _pictureUrl.includes('/placeholders')
      ? theme.pictureUrl || createPictureUrlFromColor(theme.palette.primary.dark)
      : _pictureUrl;

  return pictureUrl;
}

export default usePictureUrl;

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { DEFAULT_WIDGET_LAYOUT_ID } from 'components/HomepageWidgets/consts';

function useWidgetLayoutId() {
  const { search } = useLocation();
  const params = queryString.parse(search);
  const previewLayoutId = params.previewLayoutId as string;

  return previewLayoutId || DEFAULT_WIDGET_LAYOUT_ID;
}

export default useWidgetLayoutId;

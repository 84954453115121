import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import readableColor from 'styles/skipper/readableColor';

import { makeStyles } from 'tss-react/mui';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';

/**
 * Want to link to an attributes action? Use this link!
 *
 * This component is wrapping `react-router-dom`s `Link` component. Checkout
 * its API for additional props that are available.
 */
function FieldLink(props) {
  let {
    value,
    fieldType,
    classes: classesFromProps,
    className,
    children,
    closeSnackbar,
    themed,
    handleClickChildren,
    handleClickCopyIcon,
    ...otherProps
  } = props;
  const { classes } = useStyles(undefined, { props: { classes: classesFromProps } });
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    if (handleClickCopyIcon) handleClickCopyIcon();
    enqueueSnackbar(`“${value}” copied to clipboard!`, {
      variant: 'success',
    });
  };

  let to = null;

  switch (fieldType) {
    case 'email':
      to = `mailto:${value}`;
      break;
    case 'phoneNumber':
      if (typeof value !== 'string') {
        to = `tel:`;
      } else {
        to = `tel:${value.replace(/[^\d]/g, '')}`;
      }
      break;
    case 'phone':
      if (typeof value !== 'string') {
        to = `tel:`;
      } else {
        to = `tel:${value.replace(/[^\d]/g, '')}`;
      }
      break;
    case 'link':
      otherProps = {
        ...otherProps,
        target: '_blank',
      };
      if (typeof value !== 'string') {
        to = `#`;
      } else {
        if (!value.match(/http/i)) {
          to = `http://${value}`;
        } else {
          to = value;
        }
      }
      break;
    case 'hyperlink':
      otherProps = {
        ...otherProps,
        target: '_blank',
        rel: 'noopener noreferrer',
      };
      if (typeof value !== 'string') {
        to = `#`;
      } else {
        if (!value.match(/http/i)) {
          to = `http://${value}`;
        } else {
          to = value;
        }
      }
      break;
    case 'presence':
      to = `im:sip:${value}`;
      break;

    default:
      break;
  }

  return (
    <div className={classNames(classes.root, className)} {...otherProps}>
      <a href={to} className={classes.fieldContent} onClick={handleClickChildren}>
        {children}
      </a>
      <CopyToClipboard text={value} onCopy={handleCopy}>
        <IconButton aria-label={`copy-${fieldType}`} className={classes.iconButton} size="large">
          <CopyIcon
            className={classNames(classes.copyIcon, { [classes.themedCopyIcon]: themed })}
            fontSize="inherit"
          />
        </IconButton>
      </CopyToClipboard>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none',
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  fieldContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
  },
  iconButton: { marginLeft: theme.spacing(0.5) },
  copyIcon: {
    width: 16,
    height: 16,
  },
  themedCopyIcon: {
    color: readableColor(theme.palette.primary.dark),
  },
}));

FieldLink.propTypes = {
  value: PropTypes.any.isRequired,
  fieldType: PropTypes.string.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  themed: PropTypes.bool,
  handleClickChildren: PropTypes.func,
  handleClickCopyIcon: PropTypes.func,
};

export default FieldLink;

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import MUITextField from '@mui/material/TextField';

interface Props {
  fieldKey: string;
  value: string;
  onChange: (event: any) => void;
}

function AutoSuggestTextField({ fieldKey, value, onChange, ...other }: Props) {
  const { classes } = useStyles();

  const handleChange = (event: any) => {
    if (event && event.target) {
      onChange(event);
    }
  };

  return (
    <MUITextField
      value={value || ''}
      className={classes.root}
      {...other}
      InputProps={{
        classes: {
          root: classes.color,
          underline: classes.underline,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.color,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.color,
        },
      }}
      onChange={handleChange}
    />
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    '& input::-ms-clear': {
      display: ' none !important',
    },
  },
  color: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  underline: {
    '&:after': {
      borderColor: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
    },
    '&:before': {
      borderColor: `${theme.palette.getContrastText(theme.palette.primary.main)} !important`,
    },
  },
}));

export default AutoSuggestTextField;

// React
import React, { useState, useRef, useEffect } from 'react';
import useAnalytics from 'helpers/useAnalytics';
import typography from 'helpers/typography';

// UI
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AdvancedSearchIcon from 'components/Icons/AdvancedSearchIcon';
import Cancel from '@mui/icons-material/Cancel';
import { transparentize } from 'polished';
import bowser from 'bowser';

import { makeStyles } from 'tss-react/mui';
import readableColor from 'styles/skipper/readableColor';
import hasBadContrast from 'styles/skipper/hasBadContrast';
import classNames from 'classnames';
import useWidth from 'helpers/useWidth';

const useStyles = makeStyles()(theme => {
  const size = 80;
  const tabletSize = 60;
  const mobileSize = 30;
  const { primary, secondary, defaultAccentColor } = theme.palette;
  const inputBackground = transparentize(0.5, theme.palette.primary.dark);
  const isIE = bowser.name === 'Internet Explorer';

  return {
    root: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.getContrastText(primary.main),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        fontSize: tabletSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: mobileSize,
      },
    },
    inputContainer: {
      flex: '1 1 auto',
      display: 'flex',
      boxShadow: '0 0 0px 0px rgba(216, 196, 229, 0.24)',
      transition: 'box-shadow 0.3s ease',
      backgroundColor: inputBackground,
      borderRadius: 4,
    },
    inputContainerFocused: {
      boxShadow: `0 0 9px 2px ${transparentize(0.7, readableColor(theme.palette.primary.main))}`,
    },
    input: {
      ...typography(theme, 'display6'),
      fontFamily: 'Source Sans Pro',
      outline: 'none',
      position: 'relative',
      zIndex: theme.zIndex.surface,
      background: 'none',
      border: 'none',
      fontWeight: 'bold',
      fontSize: size, // Very unique font-size and font-weight combination
      padding: `${theme.spacing(1)} 0px`,
      '&::-ms-clear': {
        display: ' none !important',
      },
      width: '100%',
      color: theme.palette.getContrastText(primary.main),
      caretColor: hasBadContrast(secondary.main, primary.main)
        ? defaultAccentColor.main
        : secondary.main,
      minWidth: 0, // fixes firefox overflow bug
      [theme.breakpoints.down('md')]: {
        fontSize: tabletSize,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: mobileSize,
      },
    },
    placeholder: {
      marginTop: theme.spacing(1),
      position: 'absolute',
      fontSize: size,
      display: 'flex',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightBold,
      top: isIE ? 5 : 0,
      left: 72,
      // https://docs.google.com/spreadsheets/d/1_3f851fdUA7Gq7veGMynWfrvD3PV9VFlxN9DL_by0yU/edit?usp=sharing
      opacity: readableColor(primary.main) === '#000' ? 0.5 : 0.73,
      zIndex: theme.zIndex.surface,
      [theme.breakpoints.down('md')]: {
        fontSize: tabletSize,
        // center placeholder text
        top: isIE ? 3 : 0,
        left: 62,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: mobileSize,
        bottom: 9,
        left: 40,
      },
    },
    searchIcon: {
      width: size / 2,
      height: size / 2,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        width: mobileSize,
        height: mobileSize,
      },
      [theme.breakpoints.down('sm')]: {
        width: size / 4,
        height: size / 4,
        marginBottom: 5,
        marginLeft: 10,
        marginRight: 10,
      },
    },
    searchIconContainer: {
      display: 'flex',
      alignItems: 'center',
      zIndex: theme.zIndex.surface,
      flex: '1 0 auto',
      borderRadius: 1,
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 0 auto',
      borderRadius: 1,
    },
    searchButton: {
      marginRight: theme.spacing(4),
      width: 160,
      [theme.breakpoints.down('md')]: {
        width: 110,
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        width: 110,
        marginRight: theme.spacing(1.25),
      },
    },
    advancedSearchButtonContainer: {
      width: 232, // specific width need for advanced search animation
      overflow: 'hidden',
      transition: 'width 0.3s ease',
      [theme.breakpoints.down('md')]: {
        width: 50, // specific width need for advanced search animation
        overflow: 'hidden',
        transition: 'width 0.3s ease',
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        width: 35, // specific width need for advanced search animation
        overflow: 'hidden',
        transition: 'width 0.3s ease',
        display: 'flex',
      },
    },
    clearIconContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(2),
      flex: '1 0 auto',
    },
    clearIcon: {
      height: size / 2,
      width: size / 2,
      cursor: 'pointer',
      opacity: 1,
      transition: 'opacity 0.3s ease',
      [theme.breakpoints.down('md')]: {
        height: mobileSize,
        width: mobileSize,
      },
      [theme.breakpoints.down('sm')]: {
        height: size / 4,
        width: size / 4,
        cursor: 'pointer',
        opacity: 1,
        transition: 'opacity 0.3s ease',
      },
    },
    hideClearIcon: {
      cursor: 'default',
      opacity: 0,
    },
    advancedSearchIconContainer: {
      cursor: 'pointer',
      display: 'flex',
    },
    advancedSearchButtonContainerAnimate: {
      width: 0,
    },
    advancedSearchIcon: {
      [theme.breakpoints.down('md')]: {
        width: 30,
        height: 30,
        marginTop: 5,
      },
      [theme.breakpoints.down('sm')]: {
        width: 24,
        height: 24,
        marginTop: 5,
      },
    },
  };
});

function SearchInput({
  value,
  handleClear,
  onChange,
  handleShowAdvancedSearchClick,
  onSearch,
  ...inputProps
}) {
  const { classes } = useStyles();
  const width = useWidth();
  const [isFocused, setFocus] = useState(false);
  const inputRef = useRef(null);
  const analytics = useAnalytics();

  useEffect(function onMount() {
    inputRef.current.focus();
    inputRef.current.select();

    // (note - pearl) This ensures focus after tabbing into search from BigSearchLanding
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.select();
    }, 10);
  }, []);

  const buttonSize = {
    xs: 'small',
    sm: 'medium',
    md: 'medium',
    lg: 'large',
  };

  const handleSearch = () => {
    analytics.track('Searched by Click', {
      category: 'Big Search',
      label: value,
    });

    onSearch();
  };

  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.inputContainer, {
          [classes.inputContainerFocused]: isFocused,
        })}
      >
        <div className={classes.searchIconContainer} onClick={() => inputRef.current.focus()}>
          <SearchIcon className={classes.searchIcon} />
        </div>
        <input
          autoComplete={'off'}
          autoCapitalize={'off'}
          spellCheck={'false'}
          value={value}
          ref={inputRef}
          maxLength="50"
          className={classes.input}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={onChange}
          {...inputProps}
          name={'bigSearch'}
          aria-label="search-input"
        />
        <div className={classes.clearIconContainer}>
          <Cancel
            onClick={handleClear}
            className={classNames(classes.clearIcon, {
              [classes.hideClearIcon]: value.length === 0,
            })}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            size={buttonSize[width]}
            color="secondary"
            className={classes.searchButton}
            onClick={handleSearch}
          >
            Search
          </Button>
          <div
            className={classNames(classes.advancedSearchButtonContainer, {
              [classes.advancedSearchButtonContainerAnimate]: value.length > 0,
            })}
          >
            {width === 'xs' || width === 'sm' ? (
              <div
                className={classes.advancedSearchIconContainer}
                onClick={handleShowAdvancedSearchClick}
              >
                <AdvancedSearchIcon className={classes.advancedSearchIcon} />
              </div>
            ) : (
              <Button
                variant="outlined"
                size={buttonSize[width]}
                color="inherit"
                onClick={handleShowAdvancedSearchClick}
              >
                Advanced Search
              </Button>
            )}
          </div>
        </div>
      </div>
      {value.length === 0 && (
        <div
          className={classes.placeholder}
          onClick={() => {
            const inputEl = inputRef.current;
            if (!inputEl) return;
            inputEl.focus();
          }}
        >
          Search
        </div>
      )}
    </div>
  );
}

export default SearchInput;

// `;-.          ___,
//   `.`\_...._/`.-"`
//     \        /      ,
//     /()   () \    .' `-._
//    |)  .    ()\  /   _.'
//    \  -'-     ,; '. <
//     ;.__     ,;|   > \
//    / ,    / ,  |.-'.-'
//   (_/    (_/ ,;|.<`
//     \    ,     ;-`
//      >   \    /
//     (_,-'`> .'
//           (_,'
// You found pikachu !!!

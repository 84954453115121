import React from 'react';
import * as Types from 'components/Navigation/typings';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavBackAndForwardButtons from 'components/Navigation/NavBackAndForwardButtons';
import NavSearchInput from 'components/Navigation/NavigationSearchInput';
import NotificationBell from 'components/Navigation/NotificationBell';
import AdminNavLink from 'components/Navigation/AdminNavLink';
import MsTeamsNavMenu from 'components/msTeams/MsTeamsNavigation/MsTeamsNavMenu';

interface Props {
  person: any;
  searchBarClass: string;
  query?: string;
  routes: Types.Routes;
  onClickSearch: () => void;
}

function MsTeamsNavigation({ person, searchBarClass, query, routes, onClickSearch }: Props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = !!anchorEl;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.navAndSearchInputWrapper}>
          <NavBackAndForwardButtons />
          <NavSearchInput
            searchBarClass={searchBarClass}
            query={query}
            onClick={onClickSearch}
            defaultPlaceholderText={'Search by name, job title, team, skill or anything'}
          />
        </div>
        <div className={classes.menuItems}>
          <NotificationBell />
          <AdminNavLink />
          <IconButton onClick={handleOpenMenu} size="large">
            <MoreVertIcon className={classes.icon} />
          </IconButton>
        </div>
      </div>

      <MsTeamsNavMenu
        person={person}
        open={open}
        routes={routes}
        anchorEl={anchorEl}
        onCloseMenu={handleCloseMenu}
      />
    </>
  );
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  navAndSearchInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  menuItems: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

export default MsTeamsNavigation;

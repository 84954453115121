// React and Components
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import useAnalytics from 'helpers/useAnalytics';

// Style
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import hasBadContrast from 'styles/skipper/hasBadContrast';
import readableColor from 'styles/skipper/readableColor';
import { transparentize } from 'polished';

const useStyles = makeStyles()(theme => {
  const { primary, action } = theme.palette;
  const disabledBackgroundColor = hasBadContrast(action.disabled, primary.main)
    ? transparentize(0.1, readableColor(primary.main))
    : action.disabled;

  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(4),

      '& > button': {
        marginLeft: theme.spacing(1),
      },
    },
    clearButton: {
      color: theme.palette.getContrastText(primary.main),
    },
    searchButton: {},
    searchButtonDisabled: {
      backgroundColor: disabledBackgroundColor,
      color: `${transparentize(0.6, readableColor(primary.main))} !important`,
    },
  };
});

function SearchFooter(props) {
  const { clearSearch, onSearch, disabled } = props;
  const { classes } = useStyles();
  const analytics = useAnalytics();

  const handleClick = () => {
    analytics.track('Clicked to execute Advanced Search', {
      category: 'Big Search',
    });

    onSearch();
  };

  const handleClearForm = () => {
    clearSearch();
  };

  return (
    <div className={classes.root}>
      <Button onClick={handleClearForm} className={classes.clearButton}>
        Clear Fields
      </Button>
      <Button
        className={classNames(classes.searchButton, {
          [classes.searchButtonDisabled]: disabled,
        })}
        onClick={handleClick}
        variant="contained"
        color="secondary"
        disabled={disabled}
      >
        Search
      </Button>
    </div>
  );
}

SearchFooter.propTypes = {
  clearSearch: PropTypes.func,
  onSearch: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SearchFooter;

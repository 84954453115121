import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import useAnalytics from 'helpers/useAnalytics';
import Loader from 'components/skipper/Loader';
import Results from '../Results';
import NoResults from '../NoResults';
import { makeStyles } from 'tss-react/mui';
import { decomposeColor } from '@mui/material/styles';
import classNames from 'classnames';

const useStyles = makeStyles()(theme => {
  const { primary } = theme.palette;

  return {
    root: {
      minHeight: 420,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
      maxWidth: 1420,
    },
    loaderEmpty: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    loaderOverlaytransparent: {
      backgroundColor: `rgba(${decomposeColor(primary.main).values.join(',')}, 0.6)`,
    },
  };
});

interface Props {
  team: any;
  recentSearches: any;
  user: any;
  query: any;
  results: any;
  // actions
  deleteRecentSearches: any;
  deleteRecentSearch: any;
  fetchRecentSearches: any;
  updateRecentSearches: any;
  fetchAutocomplete: any;
  updateQuery: any;
  clearQuery: any;
  toggleSupportDrawer: any;
  // Loading States
  searchStatus: any;
}

function BigSearch({
  team,
  recentSearches,
  user,
  query,
  results,
  // actions
  deleteRecentSearches,
  deleteRecentSearch,
  fetchRecentSearches,
  updateRecentSearches,
  updateQuery,
  clearQuery,
  // Loading States
  searchStatus,
}: Props) {
  const { classes } = useStyles();
  const analytics = useAnalytics();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  useEffect(() => {
    if (recentSearches === undefined) {
      fetchRecentSearches(user.id);
    }
  }, [fetchRecentSearches, recentSearches, team, user]);

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);

    if (showAdvancedSearch === false) {
      analytics.track('Opened Advanced Search', {
        category: 'Big Search',
      });
    } else {
      analytics.track('Clicked BigSearch Back Button', {
        category: 'Big Search',
      });
    }
  };

  return (
    <Loader
      color="secondary"
      classes={{
        root: classes.root,
        overlayTransparent: classes.loaderOverlaytransparent,
        overlay: classNames({
          [classes.loaderEmpty]: searchStatus.isEmpty(),
        }),
      }}
      status={searchStatus}
      isEmptyView={
        <NoResults
          query={query}
          onClearSearch={clearQuery}
          onShowAdvancedSearch={toggleAdvancedSearch}
        />
      }
    >
      <Results
        results={results}
        updateRecentSearches={updateRecentSearches}
        deleteRecentSearches={deleteRecentSearches}
        deleteRecentSearch={deleteRecentSearch}
        updateQuery={updateQuery}
        query={query}
      />
    </Loader>
  );
}

export default BigSearch;

import React, { useRef, useEffect } from 'react';
// Components
import Bell from './bell.lottie';
import LottieAnimation from 'components/LottieAnimation';
import { useTheme } from '@mui/material/styles';

function BellIcon() {
  const theme = useTheme();
  const lottieRef = useRef<any>();

  useEffect(() => {
    if (lottieRef) {
      const allPathNodes = lottieRef.current.el.querySelectorAll('path') || [];
      allPathNodes.forEach((el: any) => {
        el.style.fill = theme.palette.getContrastText(theme.palette.primary.main);
      });
    }
  }, [lottieRef, theme]);

  return (
    <LottieAnimation
      animationData={Bell}
      loop={false}
      autoplay={false}
      pause={true}
      ref={lottieRef}
    />
  );
}

export default BellIcon;

// Libs
import { useEffect, useRef } from 'react';
import { useDispatch } from 'resift';
import { connect } from 'react-redux';
import useRouter from '@sift/resift/useRouter';
import hocify from 'hocify';
import compose from 'recompose/compose';

// Actions
import { updateQuery, clearQuery, toggleBigSearch, closeBigSearch } from 'store/bigSearch';

import {
  fetchRecentSearches,
  updateRecentSearches,
  deleteRecentSearches,
  deleteRecentSearch,
} from 'store/search';

import { updateSettings } from 'store/settings';

// Models
import { Status } from '@sift/resift/models/Status';

import Component from './BigSearchResults';

/**
 * this watches the pathname and closes big search when the pathname changes
 */
const withCloseEffect = hocify(() => {
  const { location } = useRouter();
  const dispatch = useDispatch();
  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }

    dispatch(closeBigSearch());
  }, [dispatch, location.key]);

  return null;
});

const mapDispatchToProps = {
  toggleBigSearch,
  updateQuery,
  clearQuery,
  fetchRecentSearches,
  updateRecentSearches,
  deleteRecentSearches,
  deleteRecentSearch,
  updateSettings,
};

const mapStateToProps = (state, ownProps) => {
  const initialStatus = new Status(
    state.bigSearch.fetchRecentSearchesStatus.value | state.bigSearch.fetchTeamStatus.value,
  );
  const team = state.bigSearch.team;

  let results = state.bigSearch.results;
  if (state.bigSearch.query === '' && initialStatus.value === 1) {
    if (Object.keys(state.bigSearch.recentSearches).length) {
      results = [state.bigSearch.recentSearches];
    } else {
      results = [];
    }

    if (team) {
      results.push(team);
    }
  }

  return {
    recentSearches: state.bigSearch.recentSearches,
    renderToLayer: ownProps.renderToLayer || false,
    team: team,
    user: state.authentication.person,
    query: state.bigSearch.query,
    results,
    searchStatus: state.bigSearch.searchStatus,
    open: state.bigSearch.open,
  };
};

export default compose(withCloseEffect, connect(mapStateToProps, mapDispatchToProps))(Component);

// React
import React from 'react';
import _get from 'lodash/get';
import { useHistory } from 'react-router';
import typography from 'helpers/typography';

// UI
import Item from './Item';
import PersonItemInfo from './PersonItemInfo';
import ResultItemPropTypes from './ResultItemPropTypes';
import SeparatedIcon from 'components/People/Separated/Icon';
import Org from 'components/Icons/Org';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Avatar from 'components/People/Avatar';
import PersonLink from 'components/Link/PersonLink';

import { makeStyles } from 'tss-react/mui';

function PersonItem(props) {
  const { value: person, fieldKey, query, ...otherProps } = props;
  const { classes } = useStyles();
  const history = useHistory();
  const { id, displayName, title, separationDate, _alternateFieldValues, name } = person;
  const altDisplayName = _get(_alternateFieldValues, 'displayName');

  const routeToOrgChart = e => {
    e.preventDefault();
    history.push({
      pathname: `/orgchart/${id}`,
    });
  };

  const matched = _get(person, '_matchedOn', [])
    .map(match => match.field)
    .join(', ');

  return (
    <PersonLink person={person} {...otherProps} classes={{ root: classes.linkRoot }}>
      <Item
        rows={altDisplayName ? 3 : 2}
        recentSearchObject={{ id, displayName, type: 'person' }}
        analyticsCardObject={{
          event: 'Profile Opened in Person Items',
          properties: {
            category: 'Big Search',
            label: matched,
          },
        }}
        analyticsIconObject={{
          event: 'Org Chart Clicked in Person Items',
          properties: {
            category: 'Big Search',
            label: matched,
          },
        }}
        icon={onClick => (
          <div onClick={onClick}>
            <Tooltip title="View Org Chart" placement="top">
              <IconButton aria-label="ViewOrg" onClick={routeToOrgChart} size="large">
                <Org fontSize="small" className={classes.rightIcon} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      >
        <Avatar
          showBorder
          person={person}
          size={50}
          classes={{
            border: classes.border,
          }}
        />

        <PersonItemInfo
          displayName={name || displayName}
          altDisplayName={altDisplayName && `(${altDisplayName})`}
          title={title}
          topRightIcon={
            separationDate ? (
              <SeparatedIcon className={classes.separatedIcon} person={person} />
            ) : null
          }
          query={query}
        />
      </Item>
    </PersonLink>
  );
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      overflow: 'hidden',
    },
    linkRoot: {
      width: '100%',
    },
    border: {
      border: `1px solid ${theme.palette.common.white}`,
    },
    text: {
      ...typography(theme, 'body1'),
      fontWeight: theme.typography.fontWeightBold,
    },
    rightIcon: {
      fill: theme.palette.getContrastText(theme.palette.primary.main),
    },
  };
});

PersonItem.propTypes = ResultItemPropTypes;

export default PersonItem;
